<template>
  <section class="section login-wrapper">
    <div class="columns is-centered is-align-items-center  is-vcentered">
      <div class="column is-half">
        <div class="box">
          <form @submit.prevent>
            <b-field
              label="User"
              label-position="inside"
            >
              <b-input
                v-model.trim="loginForm.email"
                type="email"
                value=""
                maxlength="30"
              />
            </b-field>

            <b-field
              label="Password"
              label-position="inside"
            >
              <b-input
                v-model.trim="loginForm.password"
                value=""
                type="password"
                maxlength="30"
                @keydown.enter.native="login()"
              />
            </b-field>

            <b-field>
              <b-button
                type="submit"
                @click="login()"
                @keyup.enter="login()"
              >
                Login
              </b-button>
            </b-field>
          </form>
          <a
            href="#"
            @click="loginWithApple()"
          >LOGIN WITH APPLE</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: 'Login',
  data ()
  {
    return {
      hasError: false,
      loginForm: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    login ()
    {

      this.$store.dispatch('LOGIN', {
        email: this.loginForm.email,
        password: this.loginForm.password,
      }).catch((msg) => {
        this.$buefy.notification.open({
          duration: 5000,
          message: `${msg}`,
          position: 'is-bottom',
          type: 'is-danger',
        })
      })

    },
    loginWithApple ()
    {
      this.$store.dispatch('LOGIN_WITH_APPLE')
    },
    clearIconClick ()
    {
      this.loginForm.email = ''
    },
  },
}
</script>
<style>
.login-wrapper {
  height: 100vh;
}

.box {
  background-color: rgba(255, 255, 255, .75);
}
</style>

